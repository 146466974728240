// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import { USE_NATIVE_SCROLL } from './core/config'
import ScrollService, {
    disableScrolling,
    enableScrolling,
    scrollToElement,
} from './services/Scroll'

import { HeroSectionEvents } from './components/HeroSection'

if (USE_NATIVE_SCROLL) {
    ScrollService.useNative()
} else {
    ScrollService.useVirtual()
}
disableScrolling()

import EventBus from './core/EventBus'
EventBus.on('content:change', () => {
    ScrollService.resize()
})

EventBus.on(HeroSectionEvents.AnimationComplete, () => {
    enableScrolling()
    ScrollService.resize()

    if (window.location.hash) {
        const element = document.getElementById(window.location.hash.substr(1))
        if (element.matches('.Section:not(.Section--appended):not(.Section--bare), .Footer')) {
            scrollToElement(element, {
                offset: 0,
            })
        } else {
            scrollToElement(element)
        }
    }
})

import { loadComponents } from './core/componentUtils'

loadComponents().then(() => {
    ScrollService.resize()
    EventBus.emit('page:ready')
})

/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'
